import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-blog-starter/src/templates/single-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p" {...{
        "className": "firstword"
      }}>{`Mabni`}</strong>{` Or `}<strong parentName="p" {...{
        "className": "firstword"
      }}>{`مبني`}</strong>{` are الكلمات in Arabic grammar whose endings are fixed.`}</p>
    <br />
    <p>{`The ending of Mabni can be,`}</p>
    <ul>
      <li parentName="ul">{`Sukoon (السكون)`}
        <ul parentName="li">
          <li parentName="ul">{`e.g., من Or أجل (meaning of نعم) Or كم Or هل`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Damma (الضم)`}
        <ul parentName="li">
          <li parentName="ul">{`e.g., قبلُ Or بعدُ Or نحنُ Or قطُّ`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Fatha (الفتح)`}
        <ul parentName="li">
          <li parentName="ul">{`e.g. اينَ Or أحدَ عشرَ Or شَتَّانَ (meaning افترق e.g. شَتَّانَ ما بين الهدى والضلال)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Kasra (الكسر)`}
        <ul parentName="li">
          <li parentName="ul">{`e.g. أمسِ (meaning اليوم السابق) Or الأمسِ (meaning الماضي/past)`}</li>
        </ul>
      </li>
    </ul>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "351px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "79.75460122699387%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAA9hAAAPYQGoP6dpAAACFklEQVQ4y5WT22sTURDG95/2RX1S8ElF8EGKRRAREZEieEEseGnSpC1KYnOrFdvYpk2zSXaz93P9PHParOsSijkw7JzfzHxnzmUdlIbWeu7RBFop8y0YcehS7t/hLBJjXKHvMvwecwwDYOBpYwrHU+LccAYu1ELRhYJexNEZCLSPGT7U97HZGqLeGWG7N0bvRKI94AgSsZzg/kij+cvHleu3cO3mHVy9cRv3V5/j55nC3qlYTtCPqDvqktsuGwcRmocRWkeZZS0TC5cRlErDnXGMjLmhxNFpbG1s/JHPbUwpfbngPFBOUFwgi1JrSoiFL6JY48xBOUgdsJSDM/GPESt3V6x3FospW0hzngkoqSCNkSCxy0Rzwfz8TCHLeM6yhOUF5Ft+0T0tVBSzgsUzocvgRiyfSw1x8YBpkC8LnVHuvNP8DCmgsgzx2Efmh3kysfBsCpmk+e/GohiBYZqxfEfxZIbEGPj5rhzu+0i/7mBSqcDd+AIZRxBBgGCrhuHnT/BqVcNi8NkM8c4WJtXKOUsSiJmPoF7DtFrFdHMDPAzhyOkE/Xdv8PHRKtovX0AZMWUSyV+7dxf9t6+hwgDS8/Dj1RrWH67g0DBtFmaui+0nj/F+5QEaz54iMzkOrR7sNsH2utZkmtjVg93vYL0ush6x1LAYcbtlcnqGdeyRiCiC1/iGtNuxecTyS6GnosqPusT0f+T9AXLmz9IhGDAFAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "4 Mabni",
          "title": "4 Mabni",
          "src": "/static/483a504a352bde8c9101dc2f350f2844/7c2a6/4-mabni.png",
          "srcSet": ["/static/483a504a352bde8c9101dc2f350f2844/222b7/4-mabni.png 163w", "/static/483a504a352bde8c9101dc2f350f2844/ff46a/4-mabni.png 325w", "/static/483a504a352bde8c9101dc2f350f2844/7c2a6/4-mabni.png 351w"],
          "sizes": "(max-width: 351px) 100vw, 351px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <br />
    <p>{`But, the question is`}</p>
    <h2>{`How to identify Mabniyaat (المبنيات)`}</h2>
    <p>{` There are general guidelines to identify the Mabniyaat, which are as follows,`}</p>
    <ul>
      <li parentName="ul">{`All harf are Mabni `}
        <ul parentName="li">
          <li parentName="ul">{`الحروف كلها مبنية وليس لها محل من الإعراب`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Fai'l Amr is Mabni`}
        <ul parentName="li">
          <li parentName="ul">{`فعل الأمر دائما مبني`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Fai'l Madhi is Mabni`}
        <ul parentName="li">
          <li parentName="ul">{`الفعل الماضي دائماً مبني`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Fai'l Mudhari is mabni when added with ن of Tawkeed or ن of Niswa`}
        <ul parentName="li">
          <li parentName="ul">{`الفعل المضارع مبني إذا اتصلت به نون التوكيد أو نون النسوة`}</li>
        </ul>
      </li>
    </ul>
    <br />
    <p>{`Usually, Isms are Mo'rab (الأصل في الأسماء الإعراب), but there are cases when Isms are Mabni which are as follows`}</p>
    <ul>
      <li parentName="ul">{`الضمائر are Mabni`}
        <ul parentName="li">
          <li parentName="ul">{`It has محل من الإعراب`}</li>
        </ul>
      </li>
      <li parentName="ul">{`أسماء الإشارة are Mabni`}</li>
      <li parentName="ul">{`الأسماء الموصولة are Mabni`}</li>
      <li parentName="ul">{`أسماء الأفعال are Mabni`}
        <ul parentName="li">
          <li parentName="ul">{`It doesn't have محل من الإعراب`}</li>
          <li parentName="ul">{`Example آمين Or حيَّ (which is  اسم فعل أمر) Or شَتَّان (which is اسم فعل ماض) Or أُفٍّ (which is اسم فعل مضارع)`}</li>
        </ul>
      </li>
      <li parentName="ul">{`أسماء الاستفهام are Mabni`}</li>
      <li parentName="ul">{`أسماء الشرط are Mabni`}</li>
      <li parentName="ul">{`الأسماء المركبة are Mabni`}
        <ul parentName="li">
          <li parentName="ul">{`Example: أحد عشر`}</li>
        </ul>
      </li>
      <li parentName="ul">{`اسم لا is Mabni (في بعض المواضع)`}
        <ul parentName="li">
          <li parentName="ul">{`Example: لا أحدَ في البيت`}</li>
        </ul>
      </li>
      <li parentName="ul">{`المنادى is Mabni (في بعض المواضع)`}
        <ul parentName="li">
          <li parentName="ul">{`Example: يا زيدُ`}</li>
        </ul>
      </li>
      <li parentName="ul">{`أسماء متفرقة`}
        <ul parentName="li">
          <li parentName="ul">{`Example: جَيْرِ (meaning نعم) Or حذامِ (name of women) Or بعض الظروف, e.g. إذْ`}</li>
        </ul>
      </li>
    </ul>
    <br />
    <p>{`I hope now you have enough pointers to identify Mabni in an Arabic sentence.`}</p>
    <br />
    <p>{`Happy Learning :)`}</p>
    <h2>{`Reference`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.qutoofacademy.com/",
          "rel": "nofollow noopener"
        }}>{`Qutoof Academy`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      